<template>
    <div class="myKnowledege">
        <van-swipe :autoplay="3000">
            <van-swipe-item v-for="(image, index) in images" :key="index">
                <van-image :src="image" width="100%" class='knowledge-img' lazy-load/>
            </van-swipe-item>
        </van-swipe>
        <van-tabs v-model="active" @change="onChangeCategory">
            <van-tab v-for="(item, i) in categories" :key="i" :title="item.name"> </van-tab>
        </van-tabs>
        <van-cell-group class="bgNo articleList">
            <van-cell v-for='(item, i) in articleList' :key='i' @click='toDetailPage(item.id)'>
                <template #icon>
                    <van-image :src='getImageUrl(item.coverImage)' width='100' height='88' lazy-load class='article-img'/>
                </template>
                <template #title>
                    <div class='title'>
                        <div class='ArticleTitle van-multi-ellipsis--l2'>{{ item.title }}</div>
                        <div class='ArticleDes'>{{ formatDay(item.createdAt) }}</div>
                    </div>
                </template>
            </van-cell>
        </van-cell-group>
    </div>
</template>

<script>
import { getArticleCategories, getArticleList } from '@/services/article';
import { toast, formatDate, getImageUrl } from '@/util';

export default {
    // name: "index"
    data() {
        return {
            active: 0,
            categories: [],
            articleList: [],
            total: 0,
            images: [
                require('@/assets/images/knowledge-1.png'),
                require('@/assets/images/knowledge-2.png'),
                require('@/assets/images/knowledge-3.png'),
                require('@/assets/images/knowledge-4.png'),
            ],
            thumbnail: [require('@/assets/images/articleThumbnail.png')],
            getImageUrl
        };
    },
    async created() {
        await this.getArticleCategories();
    },
    methods: {
        async getArticleCategories() {
            const { code, message, data } = await getArticleCategories();
            if (!code) {
                return toast(message);
            }
            this.categories = data;
            await this.getArticleList();
        },
        async getArticleList() {
            const { code, message, data } = await getArticleList({
                categoryId: this.categories[this.active].id,
                pageNo: 1,
                pageSize: 10000,
            });
            if (!code) {
                return toast(message);
            }
            const { list, total } = data;
            this.articleList = list;
            this.total = total;
        },
        formatDay(day) {
            return formatDate(day, 'yyyy-MM-dd');
        },
        async onChangeCategory() {
            await this.getArticleList();
        },
        toDetailPage(id) {
            this.$router.push({ path: '/patient/knowledge/detail', query: { id } });
        },
    },
};
</script>

<style scoped>
.articleList {
    margin: 10px;
}
.thumbnailImg {
    width: 100px;
}

.title {
    height: 100%;
    padding: 5px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ArticleTitle {
    font-size: 16px;
    color: #282a2d;
}
.ArticleDes {
    font-size: 14px;
    color: #6d7278;
}
.van-cell {
    padding: 10px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
}
.van-cell .van-image{
    margin-right: 10px;
}
.knowledge-img{
    height: 138px;
    width: 100%;
}
.article-img{
    border-radius: 10px;
    overflow: hidden;
}
</style>
