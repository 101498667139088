import { Method, request, Url } from '@/services/base';

async function getArticleCategories() {
    return await request(Url.article.getArticleCategories, Method.get);
}

async function getArticleList({ categoryId, pageNo, pageSize }) {
    return await request(Url.article.getArticleList, Method.get, { categoryId, pageNo, pageSize });
}

async function getArticle(id) {
    return await request(Url.article.getArticle, Method.get, { id });
}
export { getArticleCategories, getArticleList, getArticle };
